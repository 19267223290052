export default function Returns() {
  return (
    <div className="w-full h-screen flex-col justify-center align-center items-center mx-4 md:mx-10 md:px-20 px-6 gap-1 my-8">
      <h1 className="text-center text-4xl font-semibold text-Eucalyptus-dark mb-2">
        Pat's Farms Return Policy
      </h1>
      <div className="py-3">
        <h3>Perishable Items</h3>
        <p className="italic text-gray-500">e.g. produce, meat, deli, dairy</p>
        <p>
          Perishable items must be returned within 24 hours of purchase with a
          receipt.
        </p>
      </div>
      <div className="py-3">
        <h3>Non-perishable Items</h3>
        <p className="">
          Non-perishable items must be returned within 7 days of purchase with a
          receipt.
        </p>
      </div>
      <div className="py-3">
        <h3>Reduced Price Items</h3>
        <p className="">
          Reduced produce items are final sale and are not eligible for returns.
        </p>
      </div>
      <div className="self-end">
        <p className="italic text-gray-400 mt-6">
          *We reserve the right to limit or refuse any refund at our discretion.
        </p>
      </div>
    </div>
  );
}

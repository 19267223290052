//Home.js

import { Link, NavLink } from "react-router-dom";
import PdfLink from "../components/PdfLink";

import Slides from "../components/Slides";
export default function Home() {
  return (
    <div className="w-full min-h-full flex-col justify-center align-center items-center md:mx-3 my-5">
      <Ad />
    </div>
  );
}

function Ad() {
  return (
    <div className="flex flex-col lg:flex-row items-center h-auto lg:gap-10 lg:justify-around">
      <div className="flex  text-center gap-3">
        <div>
          <PdfLink title="This week's sales" Pdf="/flyer.pdf" />
          <p>Friday, September 13 - Thursday, September 19</p>
        </div>
      </div>
      <div className=" flex">
        <Slides />
      </div>
    </div>
  );
}

import "./App.css";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import ContactUs from "./pages/Contact";

import Returns from "./pages/ReturnPolicy";

import Nav from "./components/Nav";
function App() {
  return (
    <div className="App">
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/returns" element={<Returns />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
